import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { ProjectDataHatScreenStyle } from './ProjectDataHatScreen.styed'
import { ProjectDataHatContextProvider } from '../../context/ProjectDataHatContext/ProjectDataHatContext'
import GlobalStyle from '../../../style/globalStyle'
import theme from '../../../style/theme'
import { ProjectDataHat } from '../../components/ProjectDataHat/ProjectDataHat'
import { ProjectStatus } from '../../components/ProjectStatus/ProjectStatus'

export default function ProjectDataHatScreen({ id, personIsAdmin, personIsSuperAdmin, personCanUpdateToSigned }) {
  return (
    <ProjectDataHatContextProvider id={id} personCanUpdateToSigned={personCanUpdateToSigned}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ProjectDataHatScreenStyle>
          <ProjectStatus personIsSuperAdmin={personIsSuperAdmin} />
          <ProjectDataHat personIsAdmin={personIsAdmin} />
        </ProjectDataHatScreenStyle>
      </ThemeProvider>
    </ProjectDataHatContextProvider>
  )
}
