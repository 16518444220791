import React from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../input/_ExportDS/Loader/Loader'
import { useProjectDataHatContext } from '../../context/ProjectDataHatContext/ProjectDataHatContext'
import { Status } from '../Status/Status'
import { ProjectStatusStyled } from './ProjectStatus.styled'

export const ProjectStatus = ({ personIsSuperAdmin }) => {
  const { t } = useTranslation()
  const { status, canUpdateToSigned } = useProjectDataHatContext()

  if (status === null) {
    return (
      <ProjectStatusStyled>
        <div className="status-wrapper">
          <Loader />
        </div>
      </ProjectStatusStyled>
    )
  }

  return (
    <ProjectStatusStyled>
      <div className="status-wrapper">
        <div className="status-box">
          <div className="current-status">
            <p>{t('project.status.current')}</p>
            <Status status={status.current} current />
          </div>
          { status.next.length > 0 && (<hr />)}
          <div className="next-status">
            {
              status.next.map((projectStatus) => (
                <Status
                  key={projectStatus.name}
                  status={projectStatus.name}
                  current={false}
                  messages={projectStatus.tooltip.messages}
                  missingFields={projectStatus.tooltip.missing_fields}
                  disabled={projectStatus.disabled || (projectStatus.name === 'mission_signed' && !canUpdateToSigned)}
                />
              ))
            }
          </div>
          {status.current !== 'lead' && personIsSuperAdmin && (
            <div className="super-admin-lead-status">
              <hr />
              <Status
                key="lead"
                status="lead"
                messages={[]}
                missingFields={[]}
                current={false}
                disabled={false}
              />
            </div>
          )}
        </div>
      </div>
    </ProjectStatusStyled>
  )
}
